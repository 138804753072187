const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: "/privacypolicy",
    exact: true,
    component: "PrivacyPolicy",
  },
  {
    path: "/cookiepolicy",
    exact: true,
    component: "CookiePolicy",
  },
  {
    path: "/contactus",
    exact: true,
    component: "ContactUs",
  },
  {
    path: "/crisis",
    exact: true,
    component: "Crisis",
  },
];

export default routes;
