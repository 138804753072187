export const BrandColours = {
    purple: {
        base: "#8995D9",
        main: "#828AD5",
        vlight: "#F1F1F8",
        dark: "#8995d980"
    },
    green: {
        base: "#5EB5BF",
        baseTranparent: "#5EB5BFE6",
        main: "#9BD888",
        baseSuperTranparent: "#5eb5bf6b",
        vlight: "#F0F9FA"
    },
    limegreen: {
        base: "#A3D98B",
        dark:"#2f3c29"
    },
    blue: {
        base: "#4785c2",
        main: "#60BBCA",
        vlight: "#F3F7FB"
    },
    monotone: {
        dark: "#333333",
        light: "#ffffff"
    },
    white: {
        base: "#F2F2F2",
        dark: "#EFEFEF"
    },
    cyan: {
        base: "#C3E7EA"
    }
};

export const Background = {
    normal: "#f2f2f2",
    dark: "#181818",
    normalTransparent: "#f2f2f2de",
    darkTransparent: "#181818d1",
    navLight: "#FFFFFFE3",
    navDark: "#2B2B2B94"
};

export const TextColours = {
    white: "rgba(255,255,255,.8)",
    black: "rgba(0,0,0,.8)",
    blackOpaque: "rgba(0,0,0,1)"
};

export const ActionColours = {
    red: "#F58A94"
};

export const BrandColourFilters = {
    purple: {
        base: "filter: invert(73%) sepia(13%) saturate(914%) hue-rotate(202deg) brightness(86%) contrast(86%);",
        dark: "filter: invert(13%) sepia(54%) saturate(4958%) hue-rotate(267deg) brightness(87%) contrast(102%);"
    },
    green: {
        base: "",
        dark: ""
    },
    blue: {
        base: "filter: invert(95%) sepia(6%) saturate(869%) hue-rotate(135deg) brightness(95%) contrast(93%);",
        dark: "filter: invert(42%) sepia(97%) saturate(4899%) hue-rotate(166deg) brightness(87%) contrast(94%);"
    },
    monotone: {
        dark: "filter: invert(12%) sepia(0%) saturate(3%) hue-rotate(77deg) brightness(100%) contrast(92%);",
        light: "filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(280deg) brightness(108%) contrast(102%);"
    }
};

export const SocialMediaColours = {
    facebook: "#3b5998",
    twitter: "#1da1f2",
    linkedin: "#0077b5",
    reddit: "#ff4500",
    pinterest: "#E60023"
};

export default BrandColours;