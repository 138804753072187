import { useState } from "react";
import { Row, Col, Drawer } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import { ActionColours, BrandColours} from "../../styles/utils/colours";
import { SvgIcon } from "../../common/SvgIcon";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
} from "./styles";

const Header = ({ t }: any) => {
  const [visible, setVisibility] = useState(false);

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    // const scrollTo = (id: string) => {
    //   const element = document.getElementById(id) as HTMLDivElement;
    //   element.scrollIntoView({
    //     behavior: "smooth",
    //   });
    //   setVisibility(false);
    // };
    return (
      <>

        {/* <CustomNavLinkSmall href="/#mission">
          <Span>{t("Mission")}</Span>
        </CustomNavLinkSmall> */}

        <CustomNavLinkSmall
          target=""
          href="/contactus"
        >
            <Span>{t("Contact Us")}</Span>
        </CustomNavLinkSmall>

        <CustomNavLinkSmall 
        target="_blank"
        href="https://hub.anxiousextrovert.co.uk/">
          <Span>{t("HÜB")}</Span>
        </CustomNavLinkSmall>

        <CustomNavLinkSmall
          target=""
          href="/crisis"
          style={{backgroundColor: ""+ActionColours.red+"",
            padding:"2px 10px",
            borderRadius:"27px"}}
        >
            <Span style={{color:""+BrandColours.monotone.light+""}}>{t("Crisis")}</Span>
        </CustomNavLinkSmall>

      </>
    );
  };

  return (
    <HeaderSection id="Header">
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <SvgIcon src="logo.svg" width="101px" height="64px" />
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={showDrawer}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} visible={visible} onClose={onClose}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={onClose}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
